import { useResourceName, useSimpleResourceList } from "src/server/resources";
import { useMatchDashboardRoute } from "src/routes";
import styled from "styled-components";
import { MOBILE_MEDIA } from "src/utils/media";
import { VersionNumber } from "src/components/VersionNumber";
import { UserDropdown } from "src/components/UserDropdown";
import { PlannerButton } from "./PlannerButton";
import { MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";

export const TopBar: React.VFC = () => {
  const resourceId = useMatchDashboardRoute();
  const { name } = useResourceName(resourceId);

  return (
    <TopBarContainer>
      {name
        ? <Typography style={{ color: "white" }}>{name}</Typography>
        : <PlannerButton />}
      <VersionNumber />
      <div style={{ paddingRight: 4 }}>
        <UserDropdown />
      </div>
    </TopBarContainer>
  );
};

export const MobileSingleResourceTopBar: React.VFC<{
  resourceId: ID | null;
  setResourceId: (id: ID | null) => void;
}> = ({ resourceId, setResourceId }) => {
  const { data } = useSimpleResourceList();

  const sortedResources = data?.resources
    .slice()
    .sort((a, b) => a.displayIndex - b.displayIndex)
    .map((x) => ({ id: x.id, name: x.name }));

  useEffect(() => {
    const first = sortedResources?.[0];
    if (first && resourceId == null) {
      setResourceId(first.id);
    }
  }, [sortedResources, resourceId, setResourceId]);

  return (
    <TopBarContainer style={{ padding: "0 3px" }}>
      <Select
        style={{
          backgroundColor: "#ccc",
          padding: "3px 1em",
          fontSize: 11,
        }}
        value={resourceId ?? ""}
        onChange={(e) => {
          const value = e.target.value as ID;
          setResourceId(value || null);
        }}
        variant="standard"
      >
        {(sortedResources ?? []).map((r) => (
          <MenuItem key={r.id} value={r.id}>
            {r.name}
          </MenuItem>
        ))}
      </Select>
      <VersionNumber />
      <div style={{ paddingRight: 4 }}>
        <UserDropdown />
      </div>
    </TopBarContainer>
  );
};

const TopBarContainer = styled.div`
  width: 100%;
  height: 55px;
  background-color: ${(props) => props.theme.mainAccent};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 12px;

  a {
    text-decoration: none;
  }

  @media ${MOBILE_MEDIA} {
    height: 38px;
  }
`;
