export const theme = {
  colorways: {
    VIOLET: "#202252",
    BLUE: "#2e669e",
    PINK: "#f24b6a",
    ORANGE: "#ff830d",
    TEAL: "#10b4c0",
    PURPLE: "#ca2b80",
    BRIGHT_ORANGE: "#FFB22F",
    BRIGHT_GREEN: "#39FF14",
    BRIGHT_PINK: "#FA52F0",
    BRIGHT_YELLOW: "#FFFF33",
    BRIGHT_BLUE: "#30C5FF",
  },

  mainAccent: "#385853",
  secondaryAccent: "#283231",

  changedHighlight: "#ff19cd",

  errorSecondary: "#ffa5b0",
  errorPrimary: "#ff002f",
  errorIcon: "#ff002f",

  placeholderPrimary: "#eaeaec",
  placeholderSecondary: "#d2d2d2",
  placeholderAccent: "#aeafb9",

  lightButtonBorder: "#eff1f7",
  lightTint: "#eff1f7",
  lighterTint: "#f7f8fb",

  runBorder: "#283231",

  chainColor: "#4a4a4a",

  timelineText: "#4a4a4a",
  timelineTextLight: "#c6c6c6",

  resourceDivider: "#dfe2e8",

  itemDescription: "#000",
  descriptionIcon: "#c9cddd",

  historyText: "#adb2c9",

  settingsSecondary: "#9495ae",
  clipboard: "#9495ae",
  calmText: "#4a4a4a",

  linkColor: "#5a77fe",

  attachFileText: "#bbc1d2",
};

export type Theme = typeof theme;

declare module "styled-components" {
  interface DefaultTheme extends Theme {}
}

export type ColorwayName =
  | "VIOLET"
  | "BLUE"
  | "PINK"
  | "ORANGE"
  | "TEAL"
  | "PURPLE"
  | "BRIGHT_ORANGE"
  | "BRIGHT_GREEN"
  | "BRIGHT_PINK"
  | "BRIGHT_YELLOW"
  | "BRIGHT_BLUE";

export interface ItemColorway {
  standardBackground: string;
  selectedBorder: string;
  selectedBackground: string;
  durationBackground: string;
}

export const colorways: Record<ColorwayName, ItemColorway> = {
  VIOLET: {
    standardBackground: "#e0e0ee",
    selectedBorder: "#5b61ff",
    selectedBackground: theme.colorways.VIOLET,
    durationBackground: "rgba(166, 166, 206, 0.45)",
  },
  BLUE: {
    standardBackground: "#dee4eb",
    selectedBorder: "#63a9ee",
    selectedBackground: theme.colorways.BLUE,
    durationBackground: "rgba(46, 102, 158, 0.5)",
  },
  PINK: {
    standardBackground: "#f4e0e4",
    selectedBorder: "#ff98ab",
    selectedBackground: theme.colorways.PINK,
    durationBackground: "rgba(242, 75, 106, 0.5)",
  },
  ORANGE: {
    standardBackground: "#f6e7d9",
    selectedBorder: "#ffb874",
    selectedBackground: theme.colorways.ORANGE,
    durationBackground: "rgba(255, 131, 13, 0.5)",
  },
  TEAL: {
    standardBackground: "#d9edee",
    selectedBorder: "#31ddee",
    selectedBackground: theme.colorways.TEAL,
    durationBackground: "rgba(16, 180, 192, 0.5)",
  },
  PURPLE: {
    standardBackground: "#f0dce7",
    selectedBorder: "#ff4ead",
    selectedBackground: theme.colorways.PURPLE,
    durationBackground: "rgba(202, 43, 128, 0.5)",
  },
  BRIGHT_ORANGE: {
    standardBackground: "#F6E7D9",
    selectedBorder: "#FFB874",
    selectedBackground: theme.colorways.BRIGHT_ORANGE,
    durationBackground: "rgba(255, 105, 13, 0.5)",
  },
  BRIGHT_GREEN: {
    standardBackground: "#C0FFB5",
    selectedBorder: "#7FFF69",
    selectedBackground: theme.colorways.BRIGHT_GREEN,
    durationBackground: "rgb(57, 255, 20, 0.5)",
  },
  BRIGHT_YELLOW: {
    standardBackground: "#FFFF77",
    selectedBorder: "#FDF248",
    selectedBackground: theme.colorways.BRIGHT_YELLOW,
    durationBackground: "rgba(255, 240, 31, 0.5)",
  },
  BRIGHT_PINK: {
    standardBackground: "#FAC8F0",
    selectedBorder: "#FA8FF0",
    selectedBackground: theme.colorways.BRIGHT_PINK,
    durationBackground: "rgba(250, 82, 240, 0.5)",
  },
  BRIGHT_BLUE: {
    standardBackground: "#87D7FF",
    selectedBorder: "#67DCFF",
    selectedBackground: theme.colorways.BRIGHT_BLUE,
    durationBackground: "rgba(48, 197, 255, 0.5)",
  },
};
