import React from "react";
import styled from "styled-components";
import ButtonBase from "@mui/material/ButtonBase";
import { NavLink } from "react-router-dom";
import { ReactComponent as Icon } from "src/assets/icons/genia-logo.svg";
import { MOBILE_MEDIA } from "src/utils/media";

export const PlannerButton: React.VFC = () => (
  <PlannerButtonContainer as={NavLink} to="/">
    <ButtonBase>
      <Icon style={{ marginTop: -2 }} />
      <span>Factory Planner</span>
    </ButtonBase>
  </PlannerButtonContainer>
);

const PlannerButtonContainer = styled(NavLink)`
  opacity: 0.6;
  border-bottom: 3px solid transparent;

  height: 100%;

  button {
    display: flex;
    padding: 0px 12px;
    height: 100%;
  }

  span {
    color: #fff;
    padding-left: 10px;
    font-weight: 500;
    font-size: 16px;
  }

  &.active {
    opacity: 1;
    border-bottom: 3px solid ${(props) => props.theme.secondaryAccent};
  }

  & > * {
    margin-top: 1.5px; /* undo border shift */
  }

  @media ${MOBILE_MEDIA} {
    span {
      font-size: 11px;
    }

    svg {
      transform: scale(0.7);
    }
  }
`;
