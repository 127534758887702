import { version } from "../package.json";

const local = {
  VERSION: version,
  PROFILE: "local",
  GRAPHQL_URI: "http://localhost:5050/graphql",
  GRAPHQL_SUBSCRIPTION_URI: "ws://localhost:5050/graphql",
  AZURE_AD_CLIENT_ID: "c1c91ed2-ba2b-424e-bf03-156c29534973",
  AZURE_AD_TENANT_ID: "b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4",
  AZURE_AD_API_IDENTIFIER:
    "api://localdev_factory_planner_api/user_impersonation",
  AZURE_AD_STORAGEAPI_IDENTIFIER:
    "https://storage.azure.com/user_impersonation",
  AZURE_BLOB_STORAGE_ACCOUNT_NAME: "localdevfactoryplanner",
};

const development = {
  VERSION: version,
  PROFILE: "development",
  GRAPHQL_URI: "https://api-factoryplanner.dev.genia.co.nz/graphql",
  GRAPHQL_SUBSCRIPTION_URI: "wss://api-factoryplanner.dev.genia.co.nz/graphql",
  AZURE_AD_CLIENT_ID: "4989428c-3345-41ce-8789-2448e085df7b",
  AZURE_AD_TENANT_ID: "b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4",
  AZURE_AD_API_IDENTIFIER: "api://staging-factoryplanner/user_impersonation",
  AZURE_AD_STORAGEAPI_IDENTIFIER:
    "https://storage.azure.com/user_impersonation",
  AZURE_BLOB_STORAGE_ACCOUNT_NAME: "devfactoryplanner",
};

const staging = {
  VERSION: version,
  PROFILE: "staging",
  GRAPHQL_URI: "https://api-factoryplanner.staging.genia.co.nz/graphql",
  GRAPHQL_SUBSCRIPTION_URI:
    "wss://api-factoryplanner.staging.genia.co.nz/graphql",
  AZURE_AD_CLIENT_ID: "4989428c-3345-41ce-8789-2448e085df7b",
  AZURE_AD_TENANT_ID: "b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4",
  AZURE_AD_API_IDENTIFIER: "api://staging-factoryplanner/user_impersonation",
  AZURE_AD_STORAGEAPI_IDENTIFIER:
    "https://storage.azure.com/user_impersonation",
  AZURE_BLOB_STORAGE_ACCOUNT_NAME: "devfactoryplanner",
};

const production = {
  VERSION: version,
  PROFILE: "production",
  GRAPHQL_URI: "https://api-factoryplanner.app.genia.co.nz/graphql",
  GRAPHQL_SUBSCRIPTION_URI: "wss://api-factoryplanner.app.genia.co.nz/graphql",
  AZURE_AD_CLIENT_ID: "f8fe4440-ddf8-466e-aafb-52b396967b64",
  AZURE_AD_TENANT_ID: "b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4",
  AZURE_AD_API_IDENTIFIER: "api://prod-factoryplanner/user_impersonation",
  AZURE_AD_STORAGEAPI_IDENTIFIER:
    "https://storage.azure.com/user_impersonation",
  AZURE_BLOB_STORAGE_ACCOUNT_NAME: "prdfactoryplanner",
};

export const env = (() => {
  const url = window.location.href;
  if (url.includes("localhost")) return local;
  if (url.includes("factoryplanner.dev.genia.co.nz")) return development;
  if (url.includes("factoryplanner-kiosk.dev.genia.co.nz")) return development;
  if (url.includes("factoryplanner.staging.genia.co.nz")) return staging;
  if (url.includes("factoryplanner-kiosk.staging.genia.co.nz")) return staging;
  if (url.includes("factoryplanner.app.genia.co.nz")) return production;
  if (url.includes("factoryplanner-kiosk.app.genia.co.nz")) return production;
  return local;
})();

export const releaseProfile = () => env.PROFILE || "local";
